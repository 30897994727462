<template>
<span :key="fileName">
    <i v-if="bsIconClass === null" :class="fallbackIcon" ></i>
    <i v-else :class="bsIconClass" ></i>
</span>
</template>

<script lang="ts">
import { fileUtils as FileUtils, importUtils } from 'o365-utils';
const { getVideoFileExtensions, getImageFileExtensions, getExtensionFromFileName } = FileUtils;
const CDNPath = importUtils.cdnBaseUrl+'/icons/';
const icons = {
    js:"javascript.svg",
    stylesheet:"css3.svg",
    dat:"dat.svg",
    nupkg:"nuget.svg",
    msg:"msg.svg",
    gif:"gif.svg",
    csharp:"csharp.svg",
    txt:"file-text-f.svg",
    vue:"vue.svg",
    zip:"zip-file.svg",
    image:"image.svg",
    // excel:"excel2.svg",
    // word:"word2.svg",
    // powerpoint:"powerpoint2.svg",
    excel:"Excel_64x.svg",
    word:"Word_64x.svg",
    powerpoint:"PowerPoint_64x.svg",
    sql:"sql-open.svg",
    html:"html5.svg",
    ts:"ttypescript.svg",
    pdf:"pdf2.svg",
    config:"config.svg",
    db:"database-code.svg",
    json:"file-json.svg",
    code:"code.svg",
    vb:"vb.svg",
    csproj:"csproj.svg",
    exe:"exe.svg",
    dll:"dll.svg",
    sh:"terminal.svg",
    pdb:"pdb-file.svg",
    video:"video.svg",
    videofile:"video-file.svg",
    
};

const iconClass = {
    video:"bi bi-file-earmark-play",
    image:"bi bi-file-earmark-image",
    zip:"bi bi-file-earmark-zip"
}

const noneIcon = "file-b.svg"

function getBsIcon(pExtension){
    if(getVideoFileExtensions().indexOf(pExtension) > -1){
        return iconClass.video;
    }
    if(getImageFileExtensions().indexOf(pExtension) > -1){
        return iconClass.image;
    }
    if(iconClass.hasOwnProperty(pExtension)){
        return iconClass[pExtension];
    }

    return null;
}

function getIconUrlFromExtension(pExtension:string){
    return CDNPath + mapExtensionToIcon(pExtension);
}

function mapExtensionToIcon(pExtension:string){
    if(!pExtension) return noneIcon;
    if(icons.hasOwnProperty(pExtension)){
        return icons[pExtension];
    }
    if(['mp4','webm','ogg'].indexOf(pExtension) > -1){
        return icons.video;
    }

    if(getVideoFileExtensions().indexOf(pExtension) > -1){
        return icons.videofile;
    }
    if(getImageFileExtensions().indexOf(pExtension) > -1){
        return icons.image;
    }
    switch(pExtension.toLocaleLowerCase()){
        case 'cs':
            return icons.csharp;
        case 'jsonp':
            return icons.json;
        case 'html':
        case 'htm':
            return icons.html;
        case 'css':
        case 'scss':
        case 'less':
        case 'saas':
            return icons.stylesheet;
        case 'xlx':
        case 'xlsx':
        case 'xlsm':
        case 'xls':
            return icons.excel;
        case 'ppt':
        case 'pptx':
            return icons.powerpoint;
        case 'doc':
        case 'docx':
            return icons.word;
        case 'sql':
        case 'sqlplan':
            return icons.sql;
 

        case 'pcx':
    
            return icons.image;
        
        default:
            return noneIcon;
    }
}

</script>

<script setup lang="ts">
import { computed } from 'vue';
const props = defineProps({
    fileName: {
        required: false,
        type: String
    },
    extension: {
        required: false,
        type: String
    },
    size: {
        required: false,
        type: String,
        default: '1rem'
    },
    fallbackIcon: {
        type: String,
        default: 'o-icon'
    }
});

const vExtension = computed(() => props.extension ?? getExtensionFromFileName(props.fileName));

const bsIconClass = computed(() => getBsIcon(vExtension.value));

const url = computed(() => {
    return getIconUrlFromExtension(vExtension.value)
});
const iconurl = computed(() => `url('${url.value}')`);
const iconsize = computed(() => `${props.size}`);
</script>
<style scoped>
    .o-icon:before{
        content: "";
        display: inline-block;
        height:v-bind(iconsize);
        width:v-bind(iconsize);
        background-size: v-bind(iconsize);
        background-image: v-bind(iconurl);
        background-repeat: no-repeat;
     
    }
</style>